<template>
  <div id="app">
	<div class="content">
		<router-view :user='userinfo'/>
	</div>
    <nav class="tab" v-if='$route.name'>
      <router-link to="/">
		  <img v-if='$route.name == "index"' src="./assets/fyb_2.png" alt="">
		  <img v-else src="./assets/fyb_1.png" alt="">
		  <span :class='$route.name == "index" ? "act":""'>操盘风云</span>
	  </router-link> 
      <router-link to="/competition">
		  <img v-if='$route.name == "competition"' src="./assets/pm_2.png" alt="">
		  <img v-else src="./assets/pm_1.png" alt="">
		  <span :class='$route.name == "competition" ? "act":""'>实盘大赛</span>
	  </router-link>
	  <router-link to="/apply">
	  		  <img v-if='$route.name == "apply"' src="./assets/jzy_2@2x.png" alt="">
	  		  <img v-else src="./assets/jzy_1@2x.png" alt="">
	  		  <span :class='$route.name == "apply" ? "act":""'>报单</span>
	  </router-link>
	  <router-link to="/user">
	  		  <img v-if='$route.name == "user"' src="./assets/wd_2@2x.png" alt="">
	  		  <img v-else src="./assets/wd_1@2x.png" alt="">
	  		  <span :class='$route.name == "user" ? "act":""'>个人中心</span>
	  </router-link>
    </nav>
    
  </div>
</template>

<script>
export default {
	data(){
		return{
			userinfo:''
		}
	},
	watch: {
	  
	},
	methods:{
		
	},
	mounted(){
		
	},
	created(){
		var userinfo=localStorage.getItem('userinfo')
		if(userinfo){
			this.userinfo=JSON.parse(userinfo)
		}
		
	},
}	
</script>

<style lang="scss">
	*{
		box-sizing: border-box;
		padding: 0;
		margin: 0;
		list-style: none;
	}
	body{
		background-color: #f5f5f5;
		max-width: 540px;
		
	}
	.content{
		height: 100vh;
		overflow-y: auto;
		padding-bottom: 0;
		.up{
		  color: #E90001 !important;
		}
		.down{
		  color: #07B20B !important;
		}
	}
	.tab{
		position: fixed;
		max-width: 540px;
		width: 100%;
		height: 50px;
		background-color: #fff;
		border-top: 1px solid #d7d7d7;
		left: 0;
		bottom: 0;
		z-index: 99999;
		display: flex;
		padding: 5px 0;
		a{
			width: 25%;
			text-align: center;
			color: #666;
			text-decoration: none;
			font-size: 12px;
		}
		.act{
			color: #ff0a01;
		}
		img{
			display: block;
			width: 25px;
			margin:0 auto;
		}
	}
</style>
