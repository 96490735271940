import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	
  {
	  path: '/reg',
	  name: '',
	  component: () => import('../views/RegisterView.vue'),
  },
  {
  	  path: '/login',
  	  name: '',
  	  component: () => import('../views/LoginView.vue'),
  },
  {
  	  path: '/edit',
  	  name: '',
  	  component: () => import('../views/EditUser.vue'),
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/ApplyView.vue'),
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/UserView.vue'),
  },
  {
  	path: '/hot',
  	name: '',
  	component: () => import('../views/HotStock.vue'),
  },
  {
  	path: '/hot/search',
  	name: '',
  	component: () => import('../views/SearchStock.vue'),
	beforeEnter: (to, from, next) => {
	        // 在进入 /home 路由前执行的逻辑
	        // 可以进行验证或权限控制
			if(from.path != '/hot/stock'){
				sessionStorage.removeItem('name')
			}
	        next();
	}
  },
  {
  	path: '/fyb',
  	name: '',
  	component: () => import('../views/FybList.vue'),
  },
  {
  	path: '/article',
  	name: '',
  	component: () => import('../views/ArticleView.vue'),
  },
  {
  	path: '/lyb',
  	name: '',
  	component: () => import('../views/LybList.vue'),
  },
  {
  	path: '/hot/stock',
  	name: '',
  	component: () => import('../views/StockDetail.vue'),
  },
  {
  	path: '/stock/today',
  	name: '',
  	component: () => import('../views/TodayStock.vue'),
  },
  {
  	path: '/userinfo',
  	name: '',
  	component: () => import('../views/HomePage.vue'),
  },
  {
  	path: '/userrank',
  	name: '',
  	component: () => import('../views/UserRank.vue'),
  },
  {
  	path: '/matchdetail',
  	name: '',
  	component: () => import('../views/MatchDetail.vue'),
  },
  {
  	path: '/group',
  	name: '',
  	component: () => import('../views/GroupDay.vue'),
  },
  {
    path: '/competition',
    name: 'competition',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/match',
    name: '',
    component: () => import('../views/MatchInfo.vue')
  },
  {
    path: '/match/info',
    name: '',
    component: () => import('../views/MatchApply.vue')
  },
  {
    path: '/match/detail',
    name: '',
    component: () => import('../views/MatchGroup.vue')
  },
  {
    path: '/match/group',
    name: '',
    component: () => import('../views/GroupList.vue')
  },
  {
    path: '/experence',
    name: '',
    component: () => import('../views/ExperenceList.vue')
  },
  {
    path: '/article/list',
    name: '',
    component: () => import('../views/ArticleList.vue')
  },
  {
    path: '/point/record',
    name: '',
    component: () => import('../views/PointList.vue')
  },
  {
    path: '/follow',
    name: '',
    component: () => import('../views/FollowList.vue')
  },
  {
    path: '/product',
    name: '',
    component: () => import('../views/ProductList.vue')
  },
  {
    path: '/product/order',
    name: '',
    component: () => import('../views/ProductOrder.vue')
  },
  {
    path: '/message',
    name: '',
    component: () => import('../views/MessageView.vue')
  },
  {
    path: '/match/apply',
    name: '',
    component: () => import('../views/CompApply.vue')
  },
  {
    path: '/black',
    name: '',
    component: () => import('../views/BlackList.vue')
  },
  {
    path: '/aboutus',
    name: '',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/buy',
    name: '',
    component: () => import('../views/ScoreBuy.vue')
  }
]

const router = new VueRouter({
  routes
})


export default router
